import { useStaticQuery, graphql, Link } from "gatsby";
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../components/layout";
import CardHomePage from "../components/card-home-page";
import { BgImage } from "gbimage-bridge";
import ContactForm from "../components/contactForm";
import Button from "react-bootstrap/Button";
import TreatmentHero from "../components/treatment-hero";
import ReviewSection from "../components/review-section";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import { checkPropertiesForNull } from "../../utils";
import { SafeHtmlParser } from "../components/safeHtmlParser";
import uuid4 from "uuid4";

const PrivateDentistExeterPage = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteUrl
        }
      }
      reviews {
        reviewCount
      }
      schemaLogo: wpMediaItem(title: { eq: "dark-logo" }) {
        altText
        localFile {
          publicURL
          childImageSharp {
            original {
              height
              width
            }
            gatsbyImageData(
              formats: [AUTO, WEBP]
              quality: 100
              transformOptions: { cropFocus: CENTER, fit: CONTAIN }
              layout: CONSTRAINED
              placeholder: TRACED_SVG
            )
          }
        }
      }
      pageData: wpPage(slug: { eq: "private-dentist-exeter" }) {
        slug
        seoFieldGroups {
          metaTitle
          metaDescription
          localBusinessSchema
          openGraphDescription
          openGraphTitle
          productSchema
          image {
            node {
              altText
              publicUrl
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                  original {
                    width
                    height
                  }
                }
              }
            }
          }
        }
        privateDentistExeterFieldGroups {
          dentistIntroSection {
            heading
            subHeading
            subtitle
            title
            cta {
              title
              target
              url
            }
            description
            image {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                    original {
                      width
                      height
                    }
                  }
                }
              }
            }
          }
          dentistServiceSection {
            heading
            subHeading
            description
            cta {
              target
              title
              url
            }
            services {
              title
              description
              image {
                node {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        formats: [AUTO, WEBP]
                        quality: 100
                        transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                        layout: CONSTRAINED
                        placeholder: BLURRED
                      )
                    }
                  }
                }
              }
              serviceLink {
                title
                target
                url
              }
            }
          }
          dentistCtaWithImageSection {
            heading
            description
            cta {
              title
              target
              url
            }
            backgroundImage {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                  }
                }
              }
            }
            overlayImage {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                  }
                }
              }
            }
          }
          dentistTeamsSection {
            description
            heading
            link {
              title
              target
              url
            }
            websiteLogo {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                  }
                }
              }
            }
            teams {
              nodes {
                ... on WpTeamMember {
                  id
                  slug
                  teamMemberFields {
                    image {
                      node {
                        altText
                        localFile {
                          childImageSharp {
                            gatsbyImageData(
                              formats: [AUTO, WEBP]
                              quality: 100
                              transformOptions: {
                                cropFocus: CENTER
                                fit: CONTAIN
                              }
                              layout: CONSTRAINED
                              placeholder: BLURRED
                            )
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          dentistContactSection {
            heading
            description
          }
          dentistReviewsSection {
            heading
            subHeading
          }
        }
      }
    }
  `);

  const {
    pageData: { seoFieldGroups, privateDentistExeterFieldGroups, slug },
  } = data;

  const {
    dentistIntroSection,
    dentistServiceSection,
    dentistCtaWithImageSection,
    dentistTeamsSection,
    dentistContactSection,
    dentistReviewsSection,
  } = privateDentistExeterFieldGroups;

  const siteUrl = data.site.siteMetadata.siteUrl;
  const serviceSchema = {
    "@context": "https://schema.org/",
    "@type": "Product",
    "@id":
      `https://www.dentalhouseexeter.co.uk/treatments/${slug}/#${slug}`,
    image: `https://www.dentalhouseexeter.co.uk${seoFieldGroups?.image?.node.localFile.publicURL}`,
    mpn: `https://www.dentalhouseexeter.co.uk/treatments/${slug}`,
    sku: `https://www.dentalhouseexeter.co.uk/treatments/${slug}`,
    description: `${seoFieldGroups?.metaDescription}`,
    logo: `https://www.dentalhouseexeter.co.uk${data.schemaLogo.localFile.publicURL}`,
    name: "Dental House Exeter",
    url: "https://www.dentalhouseexeter.co.uk",
    brand: {
      "@type": "Organization",
      logo: `https://www.dentalhouseexeter.co.uk${data.schemaLogo.localFile.publicURL}`,
      image: `https://www.dentalhouseexeter.co.uk${seoFieldGroups?.image?.node.localFile.publicURL}`,
      name: "Dental House Exeter",
      url: "https://www.dentalhouseexeter.co.uk",
    },
    aggregateRating: {
      "@type": "AggregateRating",
      reviewCount: `${data.reviews.reviewCount}`,
      ratingValue: "5",
      bestRating: "5",
      worstRating: "1",
    },
    review: {
      "@type": "Review",
      url: "https://www.dentalhouseexeter.co.uk/reviews",
      datePublished: "2022-03-21T17:58:29+00:00",
      reviewBody:
        "This is the best dental practice I have known. Not only in terms of expertise but the lovely sociability of every single member of the practice team.",
      author: {
        "@type": "Person",
        name: "Caroline P",
      },
      reviewRating: {
        "@type": "Rating",
        ratingValue: "5",
        bestRating: "5",
        worstRating: "1",
      },
      publisher: {
        "@type": "Organization",
        name: "Dental House Exeter",
        sameAs: "https://www.dentalhouseexeter.co.uk",
      },
    },
  };

  const breadcrumb = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: "1",
        name: "Home",
        item: {
          url: `${siteUrl}`,
          id: `${siteUrl}`,
        },
      },
      {
        "@type": "ListItem",
        position: "2",
        name: "Private Dentist Exeter",
        item: {
          url: `${siteUrl}/private-dentist-exeter`,
          id: `${siteUrl}/private-dentist-exeter`,
        },
      },
    ],
  };

  return (
    <>
      <Helmet>
        {" "}
        <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      </Helmet>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(serviceSchema)}
        </script>
      </Helmet>
      {/* <LocalBusinessSchema /> */}
      <GatsbySeo
        title={seoFieldGroups?.metaTitle}
        description={seoFieldGroups?.metaDescription}
        language="en"
        openGraph={{
          type: "website",
          url: `${siteUrl}/private-dentist-exeter`,
          title: `${seoFieldGroups?.openGraphTitle}`,
          description: `${seoFieldGroups?.openGraphDescription}`,
          images: [
            {
              url: `${seoFieldGroups?.image?.node.localFile.publicURL}`,
              width: `${seoFieldGroups?.image?.node.localFile.childImageSharp.original.width}`,
              height: `${seoFieldGroups?.image?.node.localFile.childImageSharp.original.height}`,
              alt: `${seoFieldGroups?.image?.node.altText}`,
            },
          ],
        }}
      />

      <Layout>
        {dentistIntroSection &&
          !checkPropertiesForNull(dentistIntroSection, ["heading"]) && (
            <TreatmentHero
              smallHeadingTopText={dentistIntroSection?.subHeading}
              img={dentistIntroSection?.image.node?.localFile.childImageSharp.gatsbyImageData}
              imgAlt={dentistIntroSection?.image.node?.altText}
              heading1={dentistIntroSection?.heading}
              headingSmall={dentistIntroSection?.subtitle}
              heading2={dentistIntroSection?.title}
              text={
                <SafeHtmlParser
                  htmlContent={dentistIntroSection?.description}
                />
              }
              ctaButton={dentistIntroSection?.cta}
            />
          )}
        {dentistServiceSection &&
          !checkPropertiesForNull(dentistServiceSection, ["heading"]) && (
            <section className=" py-lg-7 py-5">
              <Container>
                <Row>
                  <Col className="text-center">
                    <p className="text-accent jost-bold fs-6">
                      {dentistServiceSection?.subHeading}
                    </p>
                    <h2 className=" display-4 pb-4">
                      {dentistServiceSection?.heading}
                    </h2>
                    <SafeHtmlParser
                      htmlContent={dentistServiceSection?.description}
                    />
                  </Col>
                </Row>
                {dentistServiceSection &&
                  !checkPropertiesForNull(dentistServiceSection, [
                    "services",
                  ]) && (
                    <Row className="pt-lg-8 pt-5 justify-content-center">
                      {dentistServiceSection.services.map((item) => (
                        <CardHomePage
                          key={uuid4()}
                          imageHeight="200px"
                          headingHeight="83px"
                          image={
                            item.image.node?.localFile.childImageSharp
                              .gatsbyImageData
                          }
                          imgAlt={item.image.node?.altText}
                          title={item.title}
                          text={
                            <SafeHtmlParser htmlContent={item.description} />
                          }
                          bgColour="bg-primary"
                          url={item.serviceLink?.url}
                          buttonText={item.serviceLink?.title}
                        />
                      ))}
                    </Row>
                  )}
                {dentistServiceSection.cta && dentistServiceSection.cta.url && (
                  <Row>
                    <Col className="text-center pt-5">
                      {" "}
                      <Button
                        className="text-white py-3 px-5 w-100 w-md-auto"
                        variant="accent"
                        href={dentistServiceSection.cta.url}
                        target={dentistServiceSection.cta.target ?? "_blank"}
                        rel="noreferrer"
                      >
                        {dentistServiceSection.cta.title}
                      </Button>
                    </Col>
                  </Row>
                )}
              </Container>
            </section>
          )}
        {dentistCtaWithImageSection &&
          !checkPropertiesForNull(dentistCtaWithImageSection, ["heading"]) && (
            <section>
              <BgImage
                className="w-100 position-relative "
                image={
                  dentistCtaWithImageSection?.backgroundImage.node?.localFile
                    .childImageSharp.gatsbyImageData
                }
                alt={dentistCtaWithImageSection?.backgroundImage.node?.altText}
              >
                <div
                  style={{ opacity: 0.2, zIndex: 1 }}
                  className="bg-black position-absolute w-100 h-100 top-0 start-0"
                ></div>
                <Container style={{ zIndex: 2 }} className="position-relative">
                  <Row className="py-5 py-lg-0">
                    <Col
                      md={{ span: 4, order: "last" }}
                      className="text-lg-end pb-5 pb-md-0 "
                    >
                      <GatsbyImage
                        className="w-100"
                        image={
                          dentistCtaWithImageSection.overlayImage?.node
                            ?.localFile.childImageSharp.gatsbyImageData
                        }
                        alt={
                          dentistCtaWithImageSection.overlayImage?.node?.altText
                        }
                      />
                    </Col>
                    <Col className="py-lg-10">
                      <Link
                        to={dentistCtaWithImageSection?.cta?.url}
                        className="white-link d-inline-block"
                      >
                        <h2 className=" text-start text-white fs-1 pb-4 d-inline-block ">
                          {dentistCtaWithImageSection?.heading}
                        </h2>
                      </Link>
                      <div className="content">
                        <SafeHtmlParser
                          htmlContent={dentistCtaWithImageSection?.description}
                        />
                      </div>
                      {dentistCtaWithImageSection?.cta &&
                        dentistCtaWithImageSection?.cta?.url && (
                          <Button
                            as={Link}
                            to={dentistCtaWithImageSection?.cta?.url}
                            variant="accent"
                            className="btn btn-primary w-100 w-md-auto text-white px-5 py-2 mt-3"
                          >
                            {dentistCtaWithImageSection?.cta?.title}
                          </Button>
                        )}
                    </Col>
                  </Row>
                </Container>
              </BgImage>
            </section>
          )}
        {dentistTeamsSection &&
          !checkPropertiesForNull(dentistTeamsSection, ["teams"]) && (
            <section className="bg-primary py-lg-5">
              <Container>
                <Row className="align-items-center text-center text-lg-start">
                  <Col className="py-5 py-lg-0 pe-lg-7" lg={6}>
                    <Link
                      to={dentistTeamsSection?.link.url}
                      className="white-link"
                    >
                      <h2 className="display-4 pb-4 ">
                        <SafeHtmlParser
                          htmlContent={dentistTeamsSection?.heading}
                        />
                      </h2>
                      <div className="content">
                        <SafeHtmlParser
                          htmlContent={dentistTeamsSection?.description}
                        />
                      </div>
                    </Link>
                  </Col>
                  {dentistTeamsSection.teams &&
                    dentistTeamsSection.teams.nodes.length > 0 && (
                      <Col lg={{ span: 6 }}>
                        <Row className=" g-3 py-lg-5 pb-5 ">
                          {dentistTeamsSection.teams.nodes.map((item, i) => {
                            return (
                              <>
                                {i === 4 && (
                                  <Col xs={6} md={4}>
                                    <Link to="/">
                                      <GatsbyImage
                                        image={
                                          dentistTeamsSection.websiteLogo.node
                                            ?.localFile.childImageSharp
                                            .gatsbyImageData
                                        }
                                        alt={
                                          dentistTeamsSection.websiteLogo.node
                                            ?.altText
                                        }
                                      />
                                    </Link>
                                  </Col>
                                )}
                                <Col xs={6} md={4}>
                                  <Link to={`/biography/${item.slug}`}>
                                    <div className="bg-secondary">
                                      <GatsbyImage
                                        image={
                                          item.teamMemberFields.image.node
                                            ?.localFile.childImageSharp
                                            .gatsbyImageData
                                        }
                                        alt={
                                          item.teamMemberFields.image.node
                                            ?.altText
                                        }
                                      />
                                    </div>
                                  </Link>
                                </Col>
                              </>
                            );
                          })}
                        </Row>
                      </Col>
                    )}
                </Row>
              </Container>
            </section>
          )}
        {dentistContactSection &&
          !checkPropertiesForNull(dentistContactSection, ["heading"]) && (
            <section className="py-5 py-lg-7">
              <Container>
                <Row>
                  <Col lg={6} className="text-center text-lg-start">
                    <h2 className=" text-center text-lg-start display-4 pb-4">
                      <SafeHtmlParser
                        htmlContent={dentistContactSection?.heading}
                      />
                    </h2>

                    <SafeHtmlParser
                      htmlContent={dentistContactSection?.description}
                    />
                  </Col>
                  <Col className="" lg={6}>
                    <ContactForm />
                  </Col>
                </Row>
              </Container>
            </section>
          )}
        <ReviewSection
          noMargin
          heading={
            dentistReviewsSection?.heading ||
            "Reviews From Our Dentistry Patients"
          }
          smallheading={
            dentistReviewsSection?.subHeading || "WHAT PEOPLE SAY ABOUT US"
          }
          page="Home"
        />
      </Layout>
    </>
  );
};

export default PrivateDentistExeterPage;
